/******************** COLORES *******************************/
:root {
	--blanco: #ffffff;
	--negro: #000000;
	--gris1: #eaeaea;
}
.bg-blanco{ background-color: var(--blanco); }
.bg-negro{ background-color: var(--negro); }
.bg-gris-1{ background-color: var(--gris1); }

.t-blanco{ color: var(--blanco); !important;}
.t-negro{ color: var(--negro); !important;}

/******************** GENERALES *******************************/
html, body, h1, h2, h3, h4, h5, p, a, span{
  font-optical-sizing: auto;
  font-style: normal;
}

body{ background-color: #ffffff !important;}

a, a:active, a:focus, a:visited, a:hover{ text-decoration: none; }
a:active, a:active * { outline: none !important; -moz-outline-style: none !important; }
a:focus, a:focus * { outline: none !important; -moz-outline-style: none !important; }

h1{ font-size: 60px !important; font-family: "Bebas Neue", sans-serif !important; }
h2{ font-size: 45px !important; font-family: "Bebas Neue", sans-serif !important; }
h3{ font-size: 30px !important; font-family: "Bebas Neue", sans-serif !important; }
h4{ font-size: 25px !important; font-family: "Bebas Neue", sans-serif !important; }
h5{ font-size: 18px !important; font-family: "Bebas Neue", sans-serif !important; }
h6{ font-size: 17px !important; font-family: "Bebas Neue", sans-serif !important; }

a, p, span, body, html, ul, li{
	font-family: "Roboto", sans-serif;
	font-size: 16px;
}

.container-fluid {padding: 0px !important;}

.font-ti {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.btn-1{
	background-color: #fff;
	border-radius: 20px;
	border: 2px solid #000;
	padding: 10px 25px;
	color: #000;
	font-weight: 500;
	transition: .2s;
}
.btn-1:hover{
	box-shadow: 1px 6px 12px 0px #00000029;
	color: #565656;
	transition: .2s;
}

/******************** Animación header marquesina verde *******************************/
#header-marquee{
	background-color: #b9e55c;
	padding: 5px 0px;
}
@keyframes animatedBackground {
  from { background-position: 0px 0; }
  to { background-position: -438px 0; }
}
#header-marquee #anim {
  height: 28px;
  width:100%;
  background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/header-marquee.png?v=1728062929");
  background-repeat: repeat-x;
  animation: animatedBackground 10s linear infinite;
  line-height: 28px;
}

/******************** Cambio de colores de header a negro *******************************/
.header-bottom{
  background-color: #000 !important;
  padding: 13px 0px !important;
}
.site-nav__link {
    font-weight: 500 !important;
    color: #cacaca !important;
}
.site-header__search-wrap svg path, .site-header__link svg path{
  color: #fff !important;
}
.humberger .bar1, .humberger .bar2, .humberger .bar3 {
    background-color: #ffffff;
}


/******************** HOME *******************************/
.placeholder-noblocks {
    display: none !important;
}
#home-slide .bg-slde-1{
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-slide-1.png?v=1728062420");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
}
#home-slide .bg-slde-2{
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-slide-2.png?v=1728062337");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
}
#home-slide .carousel-indicators{
		bottom: 15px !important;
}
#home-slide .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 3px solid #000 !important;
    margin-right: 2px;
    margin-left: 2px;
}
#home-slide .carousel-indicators li.active {
    background-color: #000;
}
#home-secc-icons img{
	width: 6%;
	max-width: 90px;
  margin: 0.4%;
}
#home-secc-icons img.euro{
	max-width: 150px;
	width: 9%;
}
.product-card__overlay {
    visibility: visible !important;
    opacity: 1 !important;
    position: relative !important;
}
.product-card--style5 .product-card__overlaybottom .btn {
    width: 200px !important;
    border-radius: 30px !important;
    border: 2px solid #000 !important;
}
.product-card__info {
    padding: 0px !important;
		margin-bottom: 0px;
}
.product-card__overlaytop{
	display: none !important;
}
.product-card__name {
	line-height: 19px !important;
font-size: 15px !important;
height: 38px;
overflow: hidden;
display: grid;
text-transform: lowercase;
}
.product-card__price {
    font-weight: 700 !important;
    font-size: 16px !important;
}
.product-card__availability {
    font-weight: 700;
    color: #000000;
		font-size: 16px;
}
.customstylea3182418-cb51-4d2a-8d23-1f85b68c2579 .btn-nobackground{
	display: none !important;
}
.btn-theme:before{
	background: #ffffff;
}
.product-card__overlay-btn:hover span{
	color: #000;
}
.product-card--style5 .product-card__overlaybottom .btn svg {
    display: none;
}
.product-card {
    padding: 0px 10px;
}
.bg-marmol{
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-marmol.jpg?v=1728063304");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
}
.cont-giftcard{
	border-radius: 30px;
	border: 2px solid #000;
	background-color: #fff;
	padding: 50px;
}
.rounded-30{
	border-radius: 30px;
}
.product-single {
    margin-bottom: 0px !important;
}
.imgbg-producto{
	position: absolute;
top: 0px;
left: 0px;
bottom: 0px;
right: 0px;
height: 100%;
width: 100%;
object-fit: cover;
object-position: center;
background-color: #e7e7e7;
}
.cont-nombre-producto p{
	font-size: 55px;
    font-family: "Bebas Neue", sans-serif !important;
    font-weight: 500;
    margin-bottom: 0px;
    padding-bottom: 0px;
    line-height: 40px;
    padding-top: 20px;
}
.product-single__title{
	font-size: 25px !important;
        line-height: 25px;
    padding-top: 15px;
}
.esconder{
	display: none !important;
}
#product-descripcion{
	padding: 0px !important;
}
#product-descripcion p{
	line-height: 21px;
    font-size: 16px;
    text-align: justify;
    border-left: 2px solid #000000;
    padding-left: 10px;
}
#ProductPrice-product-template-7{
	font-family: "Bebas Neue", sans-serif !important;
	font-size: 28px !important;
}
.product-single__price-container{
	margin-bottom: 0px;
    padding-top: 15px;
}
.product-single__price-container li{
	line-height: 30px;
}

/******************** PAGINA DE PRODUCTO *******************************/
#shopify-section-product-template-7 .product-single__info-wrapper {
    background-color: #e7e7e7;
    border: 2px solid #000;
    border-radius: 30px;
}

/**.slim-glow-suplemento{
	background-color: #e7e7e7;
	background-image: url("../evolutionadvance/img/bg-producto-slimglow.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}**/
.product-form__quantity-submit {
    display: grid !important;
}
.js-qty{
	width: 100%;
}
.product-form__item .js-qty__input {
    border-bottom: 1px solid #000;
    background: #fff;
    height: 40px;
    border: 2px solid #000;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
		font-size: 20px;
}
.js-qty__adjust--plus {
    right: 13px;
}
.js-qty__adjust--minus{
	right: 13px;
	bottom: 8px;
}
#shopify-section-product-template-7 .product-single__info-wrapper {
    padding: 50px 50px;
		margin: 50px 0px;
}
#shopify-section-product-template-7 .product-single__info-wrapper.is_stuck {
    padding-top: 50px !important;
}
.breadcrumbs-style_1 {
	display: none !important;
}
.product-form__item--submit {
	margin-left: 0px !important;
    margin-bottom: 20px !important;
    margin-top: 20px;
    margin-right: 0px;
    background-color: #bee059 !important;
    border: 2px solid #000;
    border-radius: 30px;
    overflow: hidden;
}
.product-form__cart-submit {
    background-color: #b9e55c !important;
    color: #000 !important;
}
.btn-theme:before {
    background: #b9e55c;
}
.gr-btnjs{
	display: none !important;
}
#shopify-section-product-template-7 .full-width {
    padding: 25px var(--g-gutter);
    background-color: #b9e55c;
}
#shopify-section-product-template-7 .full-width a{
    font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;
}
#shopify-section-product-template-7 .full-width a:hover{
    letter-spacing: 1px;
		color: #000 !important;
}
.calltoaction-mensaje-producto{
	background-color: #000;
    border-top: 4px solid #b9e55c;
}
.calltoaction-mensaje-producto h2{
	color: #fff;
}
.calltoaction-mensaje-producto span{
	color: #b9e55c;
    font-size: 45px !important;
    text-transform: uppercase;
    font-family: "Bebas Neue", sans-serif !important;
}
.calltoaction-mensaje-producto p{
	color: #fff;
font-size: 22px;
max-width: 1000px;
text-align: center;
margin: 0 auto;
line-height: 27px;
padding-top: 20px;
}
.logos-certificaciones img{
	max-width: 80px;
}
.product-form__buynow{
	margin-left: 0px !important;
	border-radius: 30px;
	overflow: hidden;
}
#shopify-section-product-template-7 .slick-dots li button {
    border: 2px solid #000;
    background: #fff;
    height: 15px;
    width: 15px;
}
#shopify-section-product-template-7 .slick-dots li.slick-active button{
    background: #b9e55c !important;
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
    background: #b9e55c !important;
    color: #fff;
}
#AddToCartForm-product-template-7 #AddToCart-product-template-7 {
    background-color: #b9e55c !important;
}
#AddToCartForm-product-template-7 #AddToCart-product-template-7 span {
		transition: .2s;
}
#AddToCartForm-product-template-7 #AddToCart-product-template-7:hover span {
    letter-spacing: 2px;
		transition: .2s;
}
.producto-tab-beneficios h3{
	font-size: 50px !important;
    margin-bottom: 30px;
    font-weight: 700;
}
.producto-tab-beneficios h4{
	margin-bottom: 0px;
padding-top: 20px;
}
.producto-tab-beneficios ul{
     padding-left: 30px;
}
#tabs-productos .nav-pills.tabs-principales {
	display: flex;
	justify-content: center;
	text-align: center;
	border-bottom: 2px solid #000;
    padding-bottom: 30px;
    margin-bottom: 30px !important;
}
#tabs-productos .tabs-principales .nav-link{
	font-family: "Bebas Neue", sans-serif !important;
  color: #000000;
background-color: transparent;
font-size: 35px;
font-weight: 500;
border-radius: 44px;
margin: 0px;
line-height: 20px;
  padding: 20px 40px 15px 40px;
}
#tabs-productos .tabs-principales .nav-link.active{
  color: #ffffff;
  background-color: #000000;

}
#tabs-productos .secundarios .nav-link{
  color: #ffffff;
  background-color: #000000;

}

#tabs-productos #ingredientes .nav-link{
  color: #000000;
background-color: #fff;
border: 2px solid #c5c5c5;
text-align: center;
font-size: 15px;
font-weight: 500;
line-height: 18px;
padding: 7px 20px 7px 20px;
border-radius: 30px;
margin: 3px 0px;

}
#tabs-productos #ingredientes .nav-link.active{
  color: #ffffff;
    background-color: #000000;
    border-radius: 30px;
    border: 2px solid #000000;

}


/******************** FOOTER *******************************/
.footer_v2 {
    background-color: #191919 !important;
}
.footer_v2 .title_footer h4 {
    font-weight: 500 !important;
    color: #ffffff !important;
}
.footer_v2 .info_footer ul li a {
    color: #c2c2c2 !important;
    font-size: 15px !important;
}
.footer_v2 .info_footer .content_newleter {
    color: #c2c2c2 !important;
    font-size: 15px !important;
}
.footer_v2 .info_us .list-icon ul li a {
    color: #ffffff !important;
		border: 2px solid #c1eb6b !important;
}
.meganav {
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-marmol.jpg?v=1728063304");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-top: 4px solid #c1eb6b !important;
		box-shadow: 0px 10px 40px 0px #00000054;
}
.site-nav__link {
    padding: 30px 18px !important;
}
.page-container {
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-marmol.jpg?v=1728063304");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
}
#NavDrawer {
	background-image: url("https://cdn.shopify.com/s/files/1/0789/5777/3088/files/home-bg-marmol.jpg?v=1728063304");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
}

#shopify-section-list-collections-template h4 a{
	color: #000 !important;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 700;
}
#ProductSection-product-template-7 .logos-certificaciones{
	background-color: #fff;
    text-align: center;
    padding: 25px 20px;
    box-shadow: 0px 18px 20px 0px #0000001a;
    z-index: 2;
    position: relative;
} 
